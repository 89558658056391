import React, { useEffect, useState } from 'react';
import myResume from '../images/JBeatriceTanResume.pdf';

function Hero() {
  const jobTitles = ['Web Designer | Full Stack Developer'];
  const [currentTitle, setCurrentTitle] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isTyping, setIsTyping] = useState(true);

  useEffect(() => {
    const typeJobTitles = () => {
      if (currentIndex < jobTitles.length) {
        const title = jobTitles[currentIndex];
        if (isTyping) {
          if (currentTitle.length < title.length) {
            setCurrentTitle(title.substring(0, currentTitle.length + 1));
          } else {
            setIsTyping(false);
            setTimeout(() => {
              setIsTyping(true);
            });
          }
        } else {
          if (currentTitle.length > 0) {
            setCurrentTitle(title.substring(0, currentTitle.length - 1));
          } else {
            setIsTyping(true);
            setCurrentIndex(currentIndex + 1);
          }
        }
      } else {
        setCurrentIndex(0);
      }
    };

    const timeout = setTimeout(
      typeJobTitles,
      isTyping ? 90 : 50
    ); 

    return () => clearTimeout(timeout);
  }, [currentTitle, currentIndex, isTyping]);

  return (
    <div id="hero">
      <div className="content-wrapper">
        <div className="hero-about">
          <h2>
            <span className="gradient-text">Beatrice Tan</span>
          </h2>
          <h4 className="job-description">
            I am a <h3 className="job-description">{currentTitle}</h3>
          </h4>
          <div className="header-socials">
          <a href="https://www.instagram.com/beartisan/" target="_blank">
            <i className="icon fab fa-instagram" aria-label="Link to Instagram Page"></i>
          </a>
          <a href="https://www.linkedin.com/in/jbeatricetan/" target="_blank">
            <i className="icon fab fa-linkedin" aria-label="Link to LinkedIn Page"></i>
          </a>
          <a href="https://github.com/beartisan" target="_blank">
            <i className="icon fab fa-github" aria-label="Link to Github Page"></i>
          </a>
        </div>
        <div className="buttons-wrapper">
          <div className="more-button">
            <a href="#about">More about me</a>
          </div>
          <div className="resume-button">
            <a href={myResume} download target="_blank">
              Resume
            </a>
          </div>
          <div className="hire-button">
          <a href="mailto:beatricetan.dev@gmail.com">Contact Me</a>
        </div>
        </div>

       </div>
       
      </div>
    </div>
  );
}

export default Hero;
