import React, { useState, useEffect } from "react";

function Experiences() {
  const [experiences, setExperiences] = useState([]);

  useEffect(() => {
    async function fetchExperiences() {
      const response = await fetch("https://beartisan.net/laravel.beartisan.net/public_html/api/experiences");
      // const response = await fetch("/experiences.json");

      const data = await response.json();
      setExperiences(data);
      console.log(data);
    }
    fetchExperiences();
  }, []);

  function formatDate(dateString) {
    if (dateString) {
      const date = new Date(dateString);
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return date.toLocaleDateString(undefined, options);
    } else {
      return "PRESENT";
    }
  }

  return (
    <section id="experiences">
      <h2 className="experience-title">Experience</h2>
      <div className="experience-container">
        {experiences.map((experience) => (
          <div key={experience.id} className="experience-card">
            <div className="company">
              <div className="job-details">
                <div className="job-title"><h2>{experience.job_title}</h2></div>
                <p>{experience.company_name}, {experience.location}</p>
              </div>
              <div className="date-location">
                <p>{formatDate(experience.start_date)} - {formatDate(experience.end_date)}</p>
              </div>
            </div>
            <div className="description">
              <p>{experience.description}</p>
            </div>
            
          </div>
        ))}
      </div>
    </section>
  );
}

export default Experiences;