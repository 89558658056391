import React from 'react';
import { useState } from 'react';

function Nav() {
  //When the hamburger is clicked, this will show
  const [showMenu, setShowMenu] = useState(false);

  function handleMenuClick() {
    setShowMenu(!showMenu);
  }

  return (
    <nav className="nav">
      
      <div
        className="hamburger"
        aria-label="Burger Menu"
        onClick={handleMenuClick}
      >
        <span className="bar"></span>
        <span className="bar"></span>
        <span className="bar"></span>
      </div>

      <ul className={`navLinks ${showMenu ? "show" : ""}`}>
        {/* <li className="nav-link"><a href="/">Home</a></li> */}
        <li className="nav-link"><a href="#about">About</a></li>
        <li className="nav-link"><a href="#experiences" className="nav-item">Experience</a></li>
        <li className="nav-link"><a href="#projects" className="nav-item">Projects</a></li>
        <li className="nav-link"><a href="#skills" className="nav-item">Skills</a></li>
        <li className="nav-link"><a href="#qualifications" className="nav-item">Education</a></li>
        {/* <li className="nav-link"><a href="#certificates" className="nav-item">Certificates</a></li> */}
      </ul>
    </nav>
  );
}

export default Nav;


// Source where I learned navbar from : https://dev.to/devggaurav/let-s-build-a-responsive-navbar-and-hamburger-menu-using-html-css-and-javascript-4gci