import React from "react";
import myImage from "../images/Self-Portrait.jpg";

function About() {
  return (
    <section id="about">
      <div className="content-wrapper">
        <div className="about-wrapper">
          <div className="about-center">
            <h3 className="about-header">Hello World!</h3>
            <h4 className="about-text">
              My name is Beatrice Tan. Online, I am{" "}
              <span className="strong-this">beartisan</span>. I've always had a
              deep passion for creativity and the use of various media
              technologies. I graduated in Advertising Arts and then one day decided to learn coding. I enjoy designing web pages, solving problems, and am passionate in learning new technologies.
            </h4>
            
          </div>
          <div className="photo">
            <img src={myImage} alt="self-portrait" className="photo-image" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;