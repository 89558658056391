import React from 'react';
import Nav from './Nav';


function Footer() {
  return (
    <footer id="footer">
      <div class="copyright">
      <h5>© Copyright beartisan, 2023</h5>
      </div>
      {/* <div class="footerlinks">
        <a href="github.com/beartisan">
        <i class="fa-brands fa-github"></i>
          Github
        </a>
      </div> */}
    </footer>
  );
}

export default Footer;
