import React from "react"

function Contact(){
    return(
        <section id="contact">
        <div className="content-wrapper">
            <div className="contact-center">
        
            <p className="collaborate">I would love to work with and for you. Collaborate with me through <h3 className="collaborate-email">beatricetan.dev@gmail.com</h3></p>
           
        
            
            <p className="collaborate">You can also check out my social media accounts.</p>
            </div>
            <div className="socials">
            <a href="https://www.instagram.com/beartisan/" target="_blank"><i className="icon fab fa-instagram" aria-label="Link to Instagram Page"></i></a>
            <a href="https://www.linkedin.com/in/jbeatricetan/" target="_blank"><i className="icon fab fa-linkedin" aria-label="Link to LinkedIn Page"></i></a>
            <a href="https://github.com/beartisan" target="_blank"><i className="icon fab fa-github" aria-label="Link to Github Page"></i></a>
            </div>
        </div>
        </section>
    );
};
export default Contact;