import React from "react";
import { useState, useEffect } from "react";

function Skills(){
    // const data = fetch("http://localhost:8888/api/skills");
    // const res = await data.json;

    const [skills, setSkills] = useState([]);

    useEffect(() => {
        async function fetchSkills() {
            const response = await fetch("https://beartisan.net/laravel.beartisan.net/public_html/api/skills");
            // const response = await fetch("/skills.json");
            const data = await response.json();
            // console.log(data);
            setSkills(data);
          }
        fetchSkills();
    }, []);

    return (
      <section id="skills">
        <div className="content-wrapper">
          <h2 className="title-skills">Skills</h2>
          <div className="skills-container">
            {skills.map((skill) => (
              <div key={skill.id} className="skill">
                <img src={skill.url} alt={skill.title} className="skill-img" />
                {/* <p className="skill-title">{skill.title}</p> */}
              </div>
            ))}
          </div>
        </div>
      </section>
    );
}
export default Skills;