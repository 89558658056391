import React from "react";
import { useState, useEffect } from "react";

function Qualifications(){
    const [qualifications, setQualifications] = useState([]);

    useEffect(() => {
        async function fetchQualifications() {
            const response = await fetch("https://beartisan.net/laravel.beartisan.net/public_html/api/qualifications");
            // const response = await fetch("/qualifications.json");
            const data = await response.json();
            setQualifications(data);
        }
        fetchQualifications();
    }, []);
    function formatDate(dateString) {
      if (dateString) {
        const date = new Date(dateString);
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return date.toLocaleDateString(undefined, options);
      } else {
        return "present";
      }
    }
  
    return (
      <section id="qualifications" className="section">
        <h2 className="qualification-title">Education</h2>
        <div className="qualification-content">
          {qualifications.map((qualification) => (
            <div key={qualification.id} className="qualification-card">
              <div className="qualification-info">
                <h3>{qualification.program_name}</h3>
                <p className="qualification-duration">{formatDate(qualification.started_at)} - {formatDate(qualification.ended_at)}</p>
              </div>
              <div className="qualification-details">
                <p>{qualification.college_name}</p>
                <p>{qualification.location}</p>
              </div>
            </div>
          ))}
        </div>
      </section>
    );
  }
export default Qualifications;