// import logo from './logo.svg';
import React from 'react';

import './styles.css';

import Header from './components/Header'; 
import Hero from './components/Hero';
import About from './components/About';
import Skills from './components/Skills';
import Projects from './components/Projects';
// import Certificates from './components/Certificates';
import Qualifications from './components/Qualifications';
import Experiences from './components/Experiences';
import Contact from './components/Contact';
import Footer from './components/Footer';


function App() {
  return (

    <div className="page">
      <Header />
      <div id="hero-container">
        <Hero />
      </div>
        <About/>
        <Skills />
        <Projects />
        <Experiences/>
        <Qualifications />
        {/* <Certificates/> */}
      <Contact />
      <Footer />
      </div>
    

  );
}
export default App;