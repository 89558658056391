import React from 'react';
import Nav from './Nav';
import logo from '../images/logo.png'

function Header() {
  return (
    <header id="header">
      <div class="logo">
        <a href="/">
            <img src={logo} title="logo" alt="beartisan logo"/>
        </a>
      </div>
      <Nav />
    </header>
  );
}

export default Header;
