import React from "react";
import { useState, useEffect } from "react";

function Projects(){

    const [projects, setProjects] = useState([]);

    useEffect(() => {
        async function fetchProjects() {
            const response = await fetch("https://beartisan.net/laravel.beartisan.net/public_html/api/projects");
            // const response = await fetch("/projects.json");
            const data = await response.json();
            // console.log(data);

             // Sort projects by date in descending order
            const sortedProjects = data.sort((a, b) => {
              const dateA = new Date(a.updated_at);
              const dateB = new Date(b.updated_at);
              return dateB - dateA;
            });

            setProjects(sortedProjects);
            // setProjects(data);
          }
        fetchProjects();
    }, []);
        
    return (
      <section id="projects">
        <div className="content-wrapper">
          <h2 className="projects-title">My Projects</h2>
          <div className="projects-wrapper">
            {projects.map((project) => (
              <div key={project.id} className="projects-card">
                <div className="projects-photo">
                  {project.image ? (
                    <img src={project.image} width="400" alt="" />
                  ) : (
                    <p>This record does not have an image!</p>
                  )}
                </div>
                <h2>{project.title}</h2>
                <p className="projectContent">{project.content}</p>
                {project.url ? (
                  
                  <a
                    href={project.url}
                    className="view-project-btn"
                    target="_blank"
                    rel="noreferrer"
                  >
                    View Project
                  </a>
                ) : (
                  <p>No URL available for this project</p>
                )}
              </div>
            ))}
          </div>
          <hr />
        </div>
      </section>
    );
  }
export default Projects;